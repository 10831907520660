<template>
  <div class="teacher_calendar">
    <div class="top_function_btn">
      <el-button @click="changeWeek('start')">本周</el-button>
      <el-button @click="changeWeek(-7)"><i class="fa-solid fa-angle-left"></i></el-button>
      <el-button @click="changeWeek(7)"><i class="fa-solid fa-angle-right"></i></el-button>
      <div class="date">
        <span>{{ startYear }}</span>
        <span>{{ startMonth }} / {{ startDay }}</span
        ><span>~</span><span v-if="endYear">{{ endYear }}</span
        ><span>{{ endMonth }} / {{ endDay }}</span>
      </div>
    </div>
    <div class="vws-table-rule">
      <div class="vws-table-rule-heading">
        <div class="vws-time-list vws-rule-time-time vws-time-rule opacity-0"></div>
        <div class="week-rule" v-for="(day, num) in dayTable" :key="num">
          {{ day.week }}
          <p class="mt-1">{{ day.dateShow }}</p>
        </div>
      </div>
      <div class="vws-table-rule-body">
        <div class="vws-rule-time" key="day"></div>
        <div class="vws-rule-time time_list" v-for="(t, idx) in timeArray" :key="idx">
          <div class="vws-time-list vws-rule-time-time vws-time-rule" :data-val="t">{{ t }}</div>
          <div v-for="(day, num) in dayTable" @click="getInfo(num, idx)" :style="{ 'background-color': getClassColor(mixTable[num], idx) }" :key="num" :class="{ 'vws-time-list vws-rule-time-item': true, active: attributeCheck(mixTable[num], idx) }">
            <span> {{ getClassName(mixTable[num], idx) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="addClass">
    <el-drawer title="老師開課" :show-close="false" :direction="direction" size="100%" modal-class="drawer_tabs" v-model="isAddClassShow" v-if="$store.getters.mobileSize">
      <AddClass :key="componentKey" @close="isAddClassShow = false" @rerender="rerenderPage" />
    </el-drawer>
    <el-dialog title="老師開課" :show-close="false" width="80%" fullscreen="true" v-model="isAddClassShow" v-else>
      <AddClass :key="componentKey" @close="isAddClassShow = false" @rerender="rerenderPage" />
    </el-dialog>
  </div>
</template>

<script>
import AddClass from "@/components/ChargeMeTribe/TeacherCenter/TeacherClass/AddClass.vue";
const dayjs = require("dayjs");
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

export default {
  components: {
    AddClass,
  },
  provide() {
    return {
      source: () => this.sourceData,
    };
  },
  data() {
    return {
      componentKey: 0,
      isAddClassShow: false,
      sourceData: {},
      sourceTableData: [],
      method: "cycle",
      steps: 60,
      dayTable: [
        { week: "星期一", dateShow: "", year: "", month: "", day: "" },
        { week: "星期二", dateShow: "", year: "", month: "", day: "" },
        { week: "星期三", dateShow: "", year: "", month: "", day: "" },
        { week: "星期四", dateShow: "", year: "", month: "", day: "" },
        { week: "星期五", dateShow: "", year: "", month: "", day: "" },
        { week: "星期六", dateShow: "", year: "", month: "", day: "" },
        { week: "星期日", dateShow: "", year: "", month: "", day: "" },
      ],
      timeArrayLength: 24,
      timeArray: [],
      mixTable: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
      weekNum: 0,
      startMonth: "",
      startDay: "",
      endMonth: "",
      endDay: "",
      startYear: "",
      endYear: "",
      today: null,
      teacherId: 0,
    };
  },
  mounted() {
    this.initData();
    this.getCourseData();
    this.today = dayjs(this.$store.state.classForm.dateRange[0]);
  },
  methods: {
    async initData() {
      const vm = this;
      if (vm.timeArray.length > 0) {
        vm.steps = 60;
        vm.timeArrayLength = vm.timeArray.length;
      }
      if (vm.timeArray.length == 0) {
        var times = [];
        var tt = 0;
        for (var i = 0; tt < 24 * 60; i++) {
          var hh = Math.floor(tt / 60) + 9;
          var mm = tt % 60;
          if (hh >= 24) hh -= 24;
          times[i] = ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2);
          tt = tt + vm.steps;
        }
        vm.timeArray = times;
      }
      // 取得本周日期
      this.changeWeek();
    },
    turnThisWeek() {
      this.weekNum = 0;
      this.changeWeek();
    },
    async changeWeek(x) {
      const vm = this;
      vm.mixTable = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      };

      if (x == undefined || x == "start") vm.weekNum = 0;
      else vm.weekNum += x;

      if (x == "start") vm.today = dayjs();
      else if (x == undefined) vm.today = dayjs(vm.$store.state.classForm.dateRange[0]);

      var mon = vm.today.weekday(vm.weekNum + 1);
      var sun = vm.today.weekday(vm.weekNum + 7);

      vm.dayTable.forEach((x, i) => {
        var date = vm.today.weekday(vm.weekNum + i + 1);
        x.dateShow = `（${date.$M + 1} / ${date.$D}）`;
        x.year = date.$y;
        x.month = date.$M + 1;
        x.day = date.$D;
      });

      vm.startYear = mon.$y;
      vm.endYear = sun.$y;
      vm.startMonth = mon.$M + 1;
      vm.startDay = mon.$D;
      vm.endMonth = sun.$M + 1;
      vm.endDay = sun.$D;
      if (vm.startYear == vm.endYear) vm.endYear = "";

      // 初始化資料
      let sd = mon.format("YYYY-MM-DD");
      let ed = sun.format("YYYY-MM-DD");

      await vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/teacher/${this.$store.state.auth.user.id}/info`).then(res => {
        vm.teacherId = res.data.teacherId;
      });

      await vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/teacher/${vm.teacherId}/calendar?startDate=${sd}&endDate=${ed}`).then(res => {
        res.data.forEach(x => {
          vm.mixTable[x.dateIndex].push({ title: x.title, index: x.timeIndex, color: x.color, id: x.id });
        });
      });
      // console.log(vm.mixTable);
    },
    getClassName(x, idx) {
      if (x.length == 0) return;
      if (x.find(y => y.index == idx) != undefined) return x.find(y => y.index == idx).title;
    },
    getClassColor(x, idx) {
      if (x.length == 0) return;
      if (x.find(y => y.index == idx) != undefined) return x.find(y => y.index == idx).color;
    },
    attributeCheck(timeTable, idx) {
      return timeTable.find(el => el.index == idx) != undefined ? true : false;
    },
    getInfo(day, time) {
      const vm = this;
      let item = this.mixTable[day].find(el => el.index == time);

      vm.sourceData = vm.sourceTableData.find(x => x.id == Number(item.id));
      vm.$store.state.classForm.courseId = vm.sourceData.id;
      vm.$store.state.classForm.teacherId = vm.sourceData.teacherId;
      vm.$store.state.classForm.isAddNewClass = false;
      vm.isAddClassShow = true;
      vm.componentKey += 1;
    },
    getCourseData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Courses/current`)
        .then(res => {
          if (res.data.courses.length != 0) {
            vm.sourceTableData = res.data.courses;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    rerenderPage() {
      this.tableData = [];
      this.initData();
      this.getCourseData();
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher_calendar {
  width: 80%;
  margin: auto;
  .top_function_btn {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .date {
      margin-left: 1rem;
      span {
        font-weight: 700;
        font-size: 1.2rem;
        margin: 0 0.5rem;
      }
    }
  }
  .vws-table-rule-heading,
  .vws-rule-time {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.5rem;
  }
  .vws-table-rule-heading div,
  .vws-rule-time .vws-time-list {
    flex-basis: 0;
    flex-grow: 1;
    margin: 2px;
    padding: 0.28rem;
    width: 6rem;
    border-radius: 0.2rem;
  }
  .week-rule {
    font-weight: 900;
  }
  .vws-time-rule {
    width: 100px;
    display: block ruby;
    font-weight: 900;
  }
  .vws-rule-time-week,
  .vws-rule-time-item {
    background: #efefef;
    pointer-events: none;
    box-shadow: 0.5px 0.5px 0.5px 0.5px #dad9d9;
  }
  .vws-rule-time-week.active,
  .vws-rule-time-item.active {
    background: #ffd964;
    pointer-events: unset;
  }
  .vws-rule-time-week.active:hover,
  .vws-rule-time-item.active:hover {
    span {
      color: #000000;
      font-weight: 900;
    }
    cursor: pointer;
  }
  .vws-rule-time-item span {
    display: none;
  }
  .vws-rule-time-item .parent {
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #3f3f3f;
    font-size: 13px;
    font-weight: 800;
    height: 4px;
  }
  .vws-rule-time-item span {
    display: block;
    text-align: center;
    color: #000;
  }
  .time_list:nth-child(4n + 1) {
    margin-bottom: 0.5rem;
  }
}
</style>
